import { createWebHistory, createRouter } from "vue-router";
import Home      from "@/components/Home.vue";
import Products  from "@/components/Products.vue";
import Team from "@/components/Team.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;