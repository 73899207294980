<template>
    <div class="products">
        <div class="productsInner">
            <div class="productsCard">
                <div class="productIcon"><i class="fa-duotone fa-chart-mixed"></i></div>
                <div class="productTitle">ETF Sponsor</div>
                <div class="productInfo">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis blandit ipsum maximus turpis mattis scelerisque. Sed vulputate diam quis purus dignissim pulvinar. Etiam orci velit, laoreet lobortis placerat ac, congue at augue. Praesent venenatis magna vel mauris tempus, at ullamcorper elit auctor. Maecenas sem enim, finibus eu ullamcorper eu, egestas vitae mauris. Aliquam erat volutpat. Curabitur quis pharetra nisi, quis hendrerit erat.</div>
            </div>
            <div class="productsCard">
                <div class="productIcon"><i class="fa-duotone fa-landmark"></i></div>
                <div class="productTitle">ETF Partner</div>
                <div class="productInfo">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis blandit ipsum maximus turpis mattis scelerisque. Sed vulputate diam quis purus dignissim pulvinar. Etiam orci velit, laoreet lobortis placerat ac, congue at augue. Praesent venenatis magna vel mauris tempus, at ullamcorper elit auctor. Maecenas sem enim, finibus eu ullamcorper eu, egestas vitae mauris. Aliquam erat volutpat. Curabitur quis pharetra nisi, quis hendrerit erat.</div>
            </div>
            <div class="productsCard">
                <div class="productIcon"><i class="fa-duotone fa-chart-candlestick"></i></div>
                <div class="productTitle">Power My ETF</div>
                <div class="productInfo">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis blandit ipsum maximus turpis mattis scelerisque. Sed vulputate diam quis purus dignissim pulvinar. Etiam orci velit, laoreet lobortis placerat ac, congue at augue. Praesent venenatis magna vel mauris tempus, at ullamcorper elit auctor. Maecenas sem enim, finibus eu ullamcorper eu, egestas vitae mauris. Aliquam erat volutpat. Curabitur quis pharetra nisi, quis hendrerit erat.</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Products', // eslint-disable-line
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.products {
    margin-top: 65px; 
    /* This margin gives room for the header */
    min-height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../assets/whiteBackground.gif);
    background-size: cover; /* or contain depending on what you want */
    background-position: top center;
    background-repeat: no-repeat;
}
.productsInner {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 100px;
}
.productsCard {
    height: 600px;
    width: 300px;
    box-shadow: 0 0 20px #999;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, .15);  
    padding: 10px;
}
.productIcon {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 80px;
    color: #2C445C;
}
.productTitle {
    font-size: 30px;
    margin-bottom: 20px;
}
.productInfo {
    font-size: 15px;
    text-align: left;
    padding: 5px;
}
@media (max-width: 800px) {
    .productsCard { width: 250px; }
}
</style>