<template>
    <div class="home">
        <div class="homeTop">
            <div class="homeBanner">
                <div class="homeBannerTitle">Trading & ETF Experts</div>
                <div class="homeBannerText">
                    <div class="homeBannerSubTitle">We want to help you succeed</div>
                    <div class="homeBannerSubText">Whether you are launching a new transparent or (active) semi-transparent ETF or just looking for a partner to trade equities, SGTS has the expertise, experience, operations, and financial flexibility to help ensure your success.</div>
                </div>
            </div>
        </div>
        <div class="homeCenter">
            <div class="homeStocksGif"></div>
            <div class="homeStocksText">
                <div class="homeStocksTitle">Navigating the many relationships, terms, and partnerships can become overwhelming and intimidating</div>
                <div class="homeStocksSub">Explore how SGTS can help you navigate <i class="fa-regular fa-arrow-right-long"></i></div>
            </div>
        </div>
         <div class="homeBottom">
            <div class="homeBottomText">
                <div class="homeStocksTitle">SGTS was designed, built, and operates for one purpose – to allow you to focus on your processes your management and ultimately your success</div>
                <div class="homeStocksSub">Explore how SGTS keeps you focused <i class="fa-regular fa-arrow-right-long"></i></div>
            </div>
            <div class="homeTallMountain"></div>
        </div>
    </div> 
</template>

<script>
export default {
  name: 'Home', // eslint-disable-line
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.home {
    margin-top: 65px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    padding-bottom: 80px;
    background-image: url(../assets/whiteBackground.gif);
    background-size: cover; /* or contain depending on what you want */
    background-position: top center;
    background-repeat: no-repeat;
    min-height: fit-content;
    /* This margin gives room for the header */
}
.homeTop {
    background-image: url(../assets/mountain2.jpeg);
    background-size: cover; /* or contain depending on what you want */
    background-position: top center;
    background-repeat: no-repeat;
    height: 500px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.homeBanner {
    min-height: fit-content;
    width: 800px;
    padding: 20px;
    margin: 20px;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, .4);  
    border-radius: 10px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .7);
}
.homeBannerTitle {
    color: #252F31;
    font-weight: bold;
    font-size:  var(--step-3);
    padding: 10px;
    padding-top: 20px;
}
.homeBannerText {
    color: #252F31;
    font-size: var(--step-3);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.homeBannerSubTitle {
    font-size: var(--step-2);
}
.homeBannerSubText {
    font-size: var(--step-0);
}
.homeCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 100px;
    width: 100vw;
}
.homeStocksGif {
    background-image: url(../assets/stocks.gif);
    background-size: cover; /* or contain depending on what you want */
    background-position: top center;
    background-repeat: no-repeat;
    box-shadow: 0 0 20px #999;
    border-radius: 10px;
    height: 300px;
    max-width: 600px;
    min-width: 600px;
    margin-left: 20px;
    margin-right: 20px;
    flex: 1;
}
.homeStocksText {
    color: #252F31;
    flex: 1;
    /* width: 600px; */
    max-width: 600px;
    /* min-width: 600px;; */
    text-align: left;
    padding-left: 50px;
    flex: 1;
}
.homeStocksTitle {
    font-size: var(--step-0);
    font-weight: bold;
    margin-bottom: 20px;
    width: 400px;
}
.homeStocksSub {
    text-align: left;
    display: flex;
    width: fit-content;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    transition: .3s ease-in-out;
}
.homeStocksSub:hover {
    border-bottom: 2px solid #252F31;
}
.homeBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: 100px;
}
.homeTallMountain {
    background-image: url(../assets/mountain5.jpeg);
    background-size: cover; /* or contain depending on what you want */
    background-position: top center;
    background-repeat: no-repeat;
    height: 600px;
    width: 400px; 
    box-shadow: 0 0 20px #999;
    border-radius: 10px;
    /* padding-left: 20px;
    padding-right: 20px; */
}
.homeBottomText {
    color: #252F31;
    text-align: left;
    padding-left: 50px;
}

@media (max-width: 1300px) {
    .homeCenter { gap: 50px; }
}
@media (max-width: 800px) {
    .home { padding-bottom: 80px; }
    .homeBannerText  { gap: 10px; }
    .homeBannerTitle { padding-top: 10px; }
    .homeStocksGif { min-width: calc(100vw - 40px); max-width: calc(100vw - 40px); }
    .homeStocksTitle { width: 300px; }
    .homeStocksText { padding-left: 20px; }
    .homeTallMountain { width: calc(100vw - 40px); aspect-ratio: 6/4; }
    .homeBottomText { padding-left: 20px; }
}
</style>