<template>
  <Header />
  <transition><router-view /></transition>
  <Footer />
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
:root {
  --step--2: clamp(0.87rem, calc(0.82rem + 0.26vw), 1.00rem);
  --step--1: clamp(1.04rem, calc(0.96rem + 0.41vw), 1.25rem);
  --step-0: clamp(1.25rem, calc(1.13rem + 0.61vw), 1.56rem);
  --step-1: clamp(1.50rem, calc(1.32rem + 0.88vw), 1.95rem);
  --step-2: clamp(1.80rem, calc(1.55rem + 1.25vw), 2.44rem);
  --step-3: clamp(2.16rem, calc(1.81rem + 1.74vw), 3.05rem);
  --step-4: clamp(2.59rem, calc(2.11rem + 2.39vw), 3.82rem);
  --step-5: clamp(3.11rem, calc(2.46rem + 3.23vw), 4.77rem);
}
#app {
  font-family: Avenir , Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
