<template>
  <div class="header">
    <div class="headerInner">
      <div class="headerLeft">
        <img class="headerLogo" src="../assets/logo.png"/>
        <div class="headerHome"     ><router-link to="/"        >Home</router-link></div>
        <div class="headerProducts" ><router-link to="/products">Products</router-link></div>
        <div class="headerLocations"><router-link to="/team"    >Team</router-link></div>
        <div class="headerHamMenu"><i class="fa-regular fa-bars" @click="isMenuClicked = !isMenuClicked"></i></div>
      </div>

      <div class="headerRight">
        <a href="mailto:info@sgtradingsolutions.com" class="headerEmail"><i class="fa-duotone fa-envelope"></i> info@sgtradingsolutions.com</a>
        <a href="tel:1-801-683-2844" class="headerPhone"><i class="fa-duotone fa-phone"></i> 1-801-683-2844</a>
      </div>
    </div>
    <transition>
      <div class="headerHamMenuDropdown" v-if="isMenuClicked">
        <div class="hamNavLink"><router-link @click="isMenuClicked = !isMenuClicked" to="/">        Home    </router-link></div>
        <div class="hamNavLink"><router-link @click="isMenuClicked = !isMenuClicked" to="/products">Products</router-link></div>
        <div class="hamNavLink">Locations</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Header', // eslint-disable-line
  data() {
    return {
      isMenuClicked: false
    }
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
/*
  Gull Grey #9DACB5
  Outer Space #252F31
  Shadow Green #818444
  Fuscous Gray #484847
 */
.header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
}
.headerInner {
  background-color: #2C445C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  font-size: var(--step-1);
  color: white;
}
.header a { 
  text-decoration: none; 
  color: white;
}
.headerHamMenuDropdown {
  background-color: #2C445C;
}
.hamNavLink {
  color: white;
  padding: 5px;
}
.headerLeft {
  display: flex;
  align-items: center;
  gap: 30px;
}
.headerHamMenu {
  display: none;
  padding-right: 10px;
}
.headerLogo {
  height: 50px;
  padding: 5px;
  padding-bottom: 10px;
  margin-right: 100px;
  margin-left: 10px;
  cursor: pointer;
}
.headerHome {
  cursor: pointer;
  transition: .3s ease-in-out;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}
.headerHome:hover {
  border-bottom: 2px solid white;
}
.headerProducts {
  cursor: pointer;
  transition: .3s ease-in-out;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}
.headerProducts:hover {
  border-bottom: 2px solid white;
}
.headerLocations {
  cursor: pointer;
  transition: .3s ease-in-out;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}
.headerLocations:hover {
  border-bottom: 2px solid white;
}
.headerPhone, .headerEmail {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: var(--step--1);
}
.headerRight {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-right: 20px;
}

@media (max-width: 1300px) {
  .headerRight{ display: none; }
}
@media (max-width: 800px) {
  .headerHome      { display: none;  }
  .headerProducts  { display: none;  }
  .headerLocations { display: none;  }
  .headerHamMenu   { display: block; }
  .headerLeft { width: 100vw;  justify-content: space-between; }
}
</style>
