<template>
    <div class="team">
        
    </div>
</template>

<script>
export default {
  name: 'Team', // eslint-disable-line
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.team {
  margin-top: 65px; 
  min-height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/whiteBackground.gif);
  background-size: cover; /* or contain depending on what you want */
  background-position: top center;
  background-repeat: no-repeat;
}
</style>