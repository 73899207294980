<template>
  <div class="footer">
        <div class="footerCenter">
            <a href="mailto:info@sgtradingsolutions.com" class="footerEmail"><i class="fa-duotone fa-envelope"></i>info@sgtradingsolutions.com</a>
            <a href="tel:1-801-683-2844" class="footerPhone"><i class="fa-duotone fa-phone"></i>1-801-683-2844</a>
        </div>
  </div>
</template>

<script>
export default {
  name: 'Footer', // eslint-disable-line
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  /* position: fixed;
  bottom: 0px;
  width: 100vw; */
  background-color: #14212c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  font-size: var(--step--1);
  color: white;
  padding: 10px;
}
.footerCenter {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  justify-content: center;
}
.footerEmail, .footerPhone {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: var(--step--1);
  text-decoration: none;
}
.footerEmail {
  margin-right: 30px;
}

</style>
